import React from "react";
import { graphql } from "gatsby";

import { Section, WorkCategories } from "../components/elements";
import Layout from "../components/layouts/Layout";

const WorkPage = ({ data }) => {
  const node = data.allWpPage.edges[0].node;
  return (
    <Layout
      internal={true}
      title={node.pageFields.seoTitle}
      description={node.pageFields.seoDescription}
    >
      <Section>
        <h2>{node.title}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: node.content,
          }}
        ></div>
        <WorkCategories categories={data.allWpWorkCategory.edges} />
      </Section>
    </Layout>
  );
};

export default WorkPage;

export const workQuery = graphql`
  query WorkPageQuery {
    allWpPage(filter: { slug: { eq: "work" } }) {
      edges {
        node {
          id
          title
          content
          pageFields {
            seoTitle
            seoDescription
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
    allWpWorkCategory(
      sort: { fields: workCategoryFields___order, order: ASC }
    ) {
      edges {
        node {
          title
          content
          workCategoryFields {
            page
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400)
                }
              }
            }
          }
        }
      }
    }
  }
`;
